import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import get from 'lodash.get';

import Layout from '../../components/Layout/Layout';
import { Consumer, Provider } from '../../components/Tabs/Context';
import SearchResultPropertyInfo from '../../components/SearchResultPropertyInfo/SearchResultPropertyInfo';
import Spinner from '../../components/UI/Spinner/Spinner';
import { getQueryParams } from '../../helpers/helper-methods';
import { NOT_FOUND } from '../../constans/links';
import styles from './index.module.scss';
import http from '../../services/http.service';
import { cleanedUrl } from '../../constans/locations';

function ResultsShared({ location }) {
  const [selectedRooms, setSelectedRooms] = useState([]);
  // const [requestId, setRequestId] = useState(null);
  // const [propertyId, setPropertyId] = useState(null);
  const [queryData, setQueryData] = useState(null);

  useEffect(() => {
    // const params = getQueryParams(location.search);
    const params = getQueryParams(location.search);

    const {
      requestId,
      propertyId,
      adultGuests,
      kidGuests,
      checkin,
      checkout,
      //  kids,
      currency,
      detailsView,
    } = params;

    if (!(
      requestId
        && propertyId
        && adultGuests
        && kidGuests
        && checkin
        && checkout
        && currency) && !detailsView) {
      navigate(NOT_FOUND);
      return;
    }

    setQueryData(params);

    if (propertyId && detailsView) {
      http.get(`/properties/${propertyId}`)
        .then(({ data }) => {
          const cityPart = cleanedUrl(`${get(data, 'urlDestination')
                  || get(data, 'city.name')
                  || get(data, 'region.name')
                  || get(data, 'country.name')}`);
          const propertyPart = cleanedUrl(`${get(data, 'urlName') || get(data, 'title')}`);

          navigate(`/properties/${cityPart}/${propertyPart}`);
        });
    }
  }, []);

  return (
    <Layout mainClasses="column-flexed-wrapper">
      <div className="flex flex-grow-1 flex-h-start">
        <div className="container property-edit-wrapper">
          <Provider>
            <Consumer>
              {({ setActiveIndex }) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {queryData ? (
                    <SearchResultPropertyInfo
                      detailsView={queryData?.detailsView}
                      numberOfRooms={queryData.numberOfRooms}
                      requestId={queryData.requestId}
                      activePropertyId={queryData.propertyId}
                      filters={[]}
                      selectedRooms={selectedRooms}
                      setSelectedRooms={setSelectedRooms}
                      setActiveIndex={setActiveIndex}
                      filterContainerStyles="col-9 col-md-5 col-lg-3"
                      containerStyles={styles.content}
                      kids={queryData.kids}
                      currency={queryData.currency}
                      checkin={queryData.checkin}
                      checkout={queryData.checkout}
                      kidGuests={queryData.kidGuests}
                      adultGuests={queryData.adultGuests}
                    />
                  ) : <Spinner spinnerClasses="spinner-primary" />}
                </>
              )}
            </Consumer>
          </Provider>
        </div>
      </div>
    </Layout>
  );
}

export default ResultsShared;
